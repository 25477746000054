<template>
  <div class="schedaDocumento" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md10 lg8>
        <v-row v-if="dettagli_testa == 'Baggi'">
          <v-col cols="3" sm="3">
            <v-img
              alt="Logo Baggi"
              class="shrink"
              contain
              src="../assets/logoBaggi.png"
              width="350px"
            />
          </v-col>
          <v-col cols="9" sm="9">
            <p><b>BAGGI - LUX S.r.l</b></p>
            <p>Via Divisione Cuneense, 48 - 12010 Bernezzo (CN) - Tel. +39 0171 85404 - P.Iva 03019130040</p>
            <p>Web: www.baggiluxtecnica.com - Email: info@baggi-lux.com</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="3" sm="3">
            <v-img
              alt="Logo Lacelle"
              class="shrink"
              contain
              src="../assets/logo.jpg"
              width="350px"
            />
          </v-col>
          <v-col cols="9" sm="9">
            <p><b>La Celle Service</b></p>
            <p>Via da definire, 4 - 20120 Paese (CN) - Tel. +39 3357564248 - Fax +39 1231231234</p>
            <p>Web: www.lacelle.it - Email: info@lacelle.it - Pec: pec@lacelle.it</p>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="6" sm="6">
            <p><b>DOCUMENTO DI TRASPORTO E/O CONSEGNA (DPR 472/96)</b></p>
            <b><i>Luogo di Destinazione</i></b><br>
            {{ nome_destinatario }}
            <br><br>
            <span v-html="indirizzo"></span>
          </v-col>
          <v-col cols="6" sm="6">
            <p><b> Numero {{ numero }} del {{ formatDate(new Date().toISOString()) }}</b></p>
            <b><i>Destinatario</i></b><br>
            {{ nome_cliente }}
            <br><br>
            <span v-html="indirizzo_cliente"></span>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" sm="12">
            <v-simple-table class="ddt-table">
              <template v-slot:default>
                <thead style="background-color: #69696940;">
                  <tr>
                    <th class="text-left b">
                      DESCRIZIONE DEI BENI
                    </th>
                    <th class="text-left b br-0 b bl-0">
                      QT
                    </th>
                    <th class="text-left b">
                      U.M.
                    </th>
                  </tr>
                </thead>
                <tbody class="bodyBlock">
                  <tr v-for="elemento in elementi"
                      :key="elemento._id">
                    <td class="bl-1 br-1">{{ elemento.descrizione }}</td>
                    <td>{{ elemento.quantita }}</td>
                    <td class="bl-1 br-1">{{ elemento.unita_misura }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="3" sm="3">
            <p><b>ASPETTO DEI BENI:</b></p>
            {{ aspetto_esteriore }}
          </v-col>
          <v-col cols="3" sm="3">
            <p><b>CAUSALE TRASPORTO:</b></p>
            {{ causale }}
          </v-col>
          <v-col cols="2" sm="2">
            <p><b>N COLLI:</b></p>
            {{ numero_colli }}
          </v-col>
          <v-col cols="2" sm="2">
            <p><b>PESO KG:</b></p>
            {{ peso_totale }}
          </v-col>
          <v-col cols="2" sm="2">
            <p><b>PORTO:</b></p>
            {{ tipo_porto }}
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="5" sm="5">
            <p><b>ANNOTAZIONI:</b></p>
            {{ annotazioni }}
          </v-col>
          <v-col cols="6" sm="6" offset-sm="1">
            <div class="b pl-3 py-2 mb-4">
              <b>FIRMA DEL CONDUCENTE:</b>
              <v-img
                v-if="firma"
                alt="Firma"
                class=""
                :src="endpointServer + firma"
                width="120px"
                height="75px"
                @click="openModalFirma"
              />
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="100%"
                v-model="modalFirma"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-if="!firma && ruolo !== 'Cliente'"
                    class="float-right hide_print"
                    style="margin-top:-7px;"
                    v-bind="attrs"
                    v-on="on"
                  >Firma</v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card
                    class="mx-auto"
                    outlined
                  >
                    <v-btn
                      color="primary"
                      style="position:absolute;top:0;right:5px;"
                      icon
                      @click="dialog.value = false"
                    ><v-icon>fas fa-times</v-icon></v-btn>
                    <vueSignature 
                      ref="firma" 
                      :sigOption="options" 
                      :w="'100%'" 
                      :clearOnResize="true"
                      :h="heightFirma" 
                      :disabled="firmaDisabled" 
                      :defaultUrl="dataUrl"
                    ></vueSignature>
                    <v-btn
                      color="primary"
                      block
                      @click="salvaFirma"
                    >Salva</v-btn>
                  </v-card>
                </template>
              </v-dialog>
            </div>
            <div class="b pl-3 py-2"><b>FIRMA DEL CESSIONARIO:</b></div>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import apiDDT from '@/js/pages/ddt';
import { momentDate } from '@/js/formatDate';
import vueSignature from "vue-signature";

export default {
  name: 'schedaDocumento',
  components: {
    vueSignature
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      _id: this.$route.query._id,
      ruolo: this.$store.state.ruolo,
      
      numero: 0,
      nome_destinatario: '',
      nome_cliente: '',
      dettagli_testa: '',
      indirizzo: '',
      indirizzo_cliente: '',
      formValid: false,
      tab: 0,

      menuData: false,
      data: '',
      lista_stabilimenti: [],
      stabilimento: '',
      lista_clienti: [],
      cliente: '',

      elementi: [{
        fake_num: 0,
        quantita: 1,
        unita_misura: '',
        descrizione: '',
      }],

      causale: '',
      numero_colli: 1,
      peso_totale: 0,
      tipo_porto: '',
      aspetto_esteriore: '',
      annotazioni: '',

      options: { 
        penColor: "rgb(0, 0, 0)", 
        backgroundColor: "rgb(255,255,255)" 
      },
      firmaDisabled: false,
      dataUrl: "https://wallpapercave.com/wp/wp3469892.jpg",
      modalFirma: false,
      firma: undefined,

      endpointServer: process.env.VUE_APP_ENDPOINT_API + '/' || '',
      heightFirma: '0',

      timeout: undefined
    }
  },
  async created() {;
    if(
      !this._id &&
      this.$route.query._id
    ) this._id = this.$route.query._id;
    let DDT = await apiDDT.getOne(this._id);
    this.numero = DDT.numero || '';
    this.data = DDT.data || '';
    this.nome_destinatario = DDT.nome_destinatario || '';// QUI
    this.dettagli_testa = DDT.dettagli_testa || '';
    this.indirizzo = DDT.indirizzo || '';
    this.indirizzo_cliente = DDT.stabilimento ? DDT.stabilimento.indirizzo : '';
    this.nome_cliente = DDT.cliente ? DDT.cliente.nominativo1 : '';// QUI
    this.elementi = DDT.elementi || [];
    this.aspetto_esteriore = DDT.aspetto_esteriore || '';
    this.causale = DDT.causale || '';
    this.numero_colli = DDT.numero_colli || 1;
    this.peso_totale = DDT.peso_totale || 0;
    this.tipo_porto = DDT.tipo_porto || 'Franco';
    this.annotazioni = DDT.annotazioni || '';
    this.firma = DDT.firma || '';

    this.windowResized();
    window.addEventListener("resize", this.reloadPage);
  },
  watch: {
    modalFirma() {
      if(
        !this.modalFirma &&
        this.$refs.firma
      ) this.$refs.firma.clear();
    }
  },
  methods: {
    formatDate(date) {
      if(date) return momentDate(date);
      return '';
    },
    async salvaFirma(){
			var _this = this;
			//var png = _this.$refs.firma.save()
			//var jpeg = _this.$refs.firma.save('image/jpeg')
      var svg = _this.$refs.firma.save('image/svg+xml');
      if(svg) {
        let formData = new FormData();
        let finalBlob = await fetch(svg).then(res => res.blob());
        formData.append("immagine", finalBlob, _this._id + '.svg');
        await apiDDT.sendFileFirma(this.token, this._id, formData);
        let DDT = await apiDDT.getOne(this._id);
        this.firma = DDT.firma || '';
      }
      _this.modalFirma = false;
		},
    openModalFirma() {
      if(this.ruolo !== 'Cliente') this.modalFirma = true;
    },
    windowResized() {
      var win = window,
        height = parseInt(win.innerHeight || 0);
        
      this.heightFirma = (height - 130) < 600 ? (height-130)+'px' : '600px';
      this.modalFirma = false;
    },
    reloadPage() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
          location.reload();
      }, 500);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.reloadPage);
  },
}
</script>
